<template>
	<div class="grid">
		<div class="col-12">
			<div v-if="pageLoading">
				<div class="custom-skeleton">
					<div class="card">
                        <div class="flex mb-3">
                            <Skeleton height="8rem" class="mb-1"></Skeleton>
                        </div>
                        <Skeleton width="100%" height="4rem" class="my-1"></Skeleton>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
                    </div>
				</div>
			</div>
			<div v-else class="card p-2">
				<Toast/>
				<div class="surface-ground border-1 surface-border p-2">
					<div class="flex flex-grow-1 align-items-center justify-content-center my-2">
						<div class="flex flex-grow-1 align-items-center justify-content-start">
							<div class="text-xl font-semibold">Refund Request List</div>
						</div>
						<!-- <div class="flex flex-grow-1 align-items-center justify-content-end">
							<Button label="Create New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
						</div> -->
					</div>
					<Divider></Divider>
                    <div class="grid">
                        <div class="col-12 md:col-4">
							<div class="field">
								<label for="QueryAgency">Agency</label>
								<Dropdown
									class="w-full"
									id="QueryAgency"
									:filter="true"
									:options="agencies"
									optionLabel="name"
									optionValue="id"
									v-model="QueryAgency"
									placeholder="Select agency"
								/>
							</div>
						</div>
                        <div class="col-12 md:col-4">
							<div class="field">
								<label for="QueryStatus">Status</label>
								<Dropdown
									class="w-full"
									id="QueryStatus"
                                    :options="statusOptions"
                                    optionLabel="name"
                                    optionValue="value"
									v-model="QueryStatus"
									placeholder="Select status"
								/>
							</div>
						</div>
                        <div class="col-12 md:col-4">
							<div class="field">
								<label for="QueryStartDate">Start Date</label>
								<Calendar
									class="w-full"
									id="QueryStartDate"
									v-model="QueryStartDate"
									dateFormat="dd-mm-yy"
									:showButtonBar="true"
									selectionMode="single"
									:showIcon="true"
									placeholder="Select start date"
								/>
							</div>
						</div>
                        <div class="col-12 md:col-4 md:col-offset-4">
							<div class="field">
								<label for="QueryEndDate">End Date</label>
								<Calendar
									class="w-full"
									id="QueryEndDate"
									v-model="QueryEndDate"
									dateFormat="dd-mm-yy"
									:showButtonBar="true"
									selectionMode="single"
									:showIcon="true"
									placeholder="Select end date"
								/>
							</div>
						</div>
						<div class="col-12 md:col-4 lg:my-5">
							<div class="flex flex-wrap ">
								<div class="flex-1 flex align-items-center justify-content-center mx-2">
									<Button label="Clear" icon="pi pi-filter-slash" class="p-button-raised p-button-text w-full" @click="clearFilters"></Button>
								</div>
								<div class="flex-1 flex align-items-center justify-content-center mx-2">
									<Button label="Apply" icon="pi pi-filter" class="p-button-raised p-button-text w-full" @click="getFilteredList"></Button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<DataTable v-if="refundRequestList.data != ''" ref="dt" :value="refundRequestList.data" dataKey="id" responsiveLayout="scroll">
					<Column field="id" header="ID" headerStyle="width:8%; min-width:4rem;">
						<template #body="slotProps">
							#00{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="name" header="Name" headerStyle="width:18%; min-width:10rem;">
						<template #body="slotProps">
							{{slotProps.data.user.name}}
						</template>
					</Column>
					<Column field="agency" header="Agency" headerStyle="width:18%; min-width:10rem;">
						<template #body="slotProps">
							{{slotProps.data.agency.name}}
						</template>
					</Column>
					<Column field="paid_amount" header="Paid Amount" headerStyle="width:12%; min-width:8rem;">
						<template #body="slotProps">
							BDT {{slotProps.data.paid_amount}}
						</template>
					</Column>
					<Column field="refund_amount" header="Refund Amount" headerStyle="width:12%; min-width:8rem;">
						<template #body="slotProps">
							BDT {{slotProps.data.refund_amount}}
						</template>
					</Column>
					<Column field="refund_percentage" header="Refund Percentage" headerStyle="width:12%; min-width:8rem;">
						<template #body="slotProps">
							{{slotProps.data.refund_percentage}}%
						</template>
					</Column>
					<Column field="status" header="Status" headerStyle="width:10%; min-width:5rem;">
						<template #body="slotProps">
                            <span v-if="slotProps.data.status == 0">Pending</span>
                            <span v-if="slotProps.data.status == 1">Completed</span>
                            <span v-if="slotProps.data.status == -1">Declined</span>
							{{}}
						</template>
					</Column>
					<Column header="Actions" headerStyle="width:10%; min-width:8rem;">
						<template #body="slotProps">
							<!-- <Button icon="pi pi-eye" class="p-button-raised p-button-text p-button-rounded mr-2" @click="confirmDetailSelected(slotProps.data)" /> -->
							<Button icon="pi pi-pencil" class="p-button-raised p-button-text p-button-rounded mr-2" @click="updateRefund(slotProps.data)" />
							<!-- <Button icon="pi pi-trash" class="p-button-raised p-button-text p-button-rounded p-button-danger mt-2" @click="confirmDelete(slotProps.data)" /> -->
						</template>
					</Column>
					<template #footer>
						<Paginator v-model:first="refundRequestList.from" :rows="paginationValue" :totalRecords="refundRequestList.total" :rowsPerPageOptions="[10,20,50]" @page="onPage" >
						</Paginator>
					</template>
				</DataTable>

				<template v-else>
					<div class="card">
						<div class="flex align-items-stretch justify-content-center flex-wrap card-container" style="min-height: 200px">
							<div class="flex align-items-center justify-content-center">
								<Message severity="info" :closable="false">No refund request found!</Message>
							</div>
						</div>
					</div>
				</template>

				<Dialog v-model:visible="showUpdate" :style="{width: '550px'}" header="Update Refund Request" :modal="true" class="p-fluid" :closable="false">
					<div class="field">
						<label for="agency">Agency</label>
                        <InputText
							id="agency"
							v-model="valid$.agency.$model.name"
							required="true"
							placeholder="Enter Agency Name"
							:class="{'p-invalid': valid$.agency.$invalid && submitted}"
                            disabled
						/>
					</div>
					
                    <div class="field">
						<label for="status">Status</label>
                        <Dropdown
                            id="status"
							v-model="valid$.status.$model"
                            :options="statusOptions"
                            optionLabel="name"
                            optionValue="value"
                            placeholder="Select content status"
							:class="{'p-invalid': valid$.status.$invalid && submitted}"
						/>
						<span v-if="valid$.status.$error && submitted">
							<span
								id="status-error"
								v-for="(error, index) of valid$.status.$errors"
								:key="index"
							>
								<small class="p-error">{{error.$message.replace("Value", "Status")}}</small>
							</span>
						</span>
						<small
							v-else-if="(valid$.status.$invalid && submitted) || valid$.status.$pending.$response"
							class="p-error"
						>
							{{ valid$.status.required.$message.replace("Value", "Status") }}
						</small>
					</div>

                    <div class="field">
						<label for="note">Note</label>
						<Textarea
							id="note"
							v-model="valid$.note.$model"
							:autoResize="true"
							:class="{'p-invalid': valid$.note.$invalid && submitted}"
							placeholder="Enter Note"
						/>
						<span v-if="valid$.note.$error && submitted">
							<span
								id="note-error"
								v-for="(error, index) of valid$.note.$errors"
								:key="index"
							>
								<small class="p-error">{{error.$message.replace("Value", "Note")}}</small>
							</span>
						</span>
						<small
							v-else-if="(valid$.note.$invalid && submitted) || valid$.note.$pending.$response"
							class="p-error"
						>
							{{ valid$.note.required.$message.replace("Value", "Note") }}
						</small>
					</div>
					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button v-if="selectedItem != null" label="Update" icon="pi pi-check" class="p-button p-button-raised" @click="submitUpdate(!valid$.$invalid)" :loading="loading"/>
						<Button v-else label="Create" icon="pi pi-check" class="p-button p-button-raised" @click="saveFeaturedContent(!valid$.$invalid)" :loading="loading"/>
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import { reactive, ref } from 'vue';
import Axios from 'axios';
import moment from 'moment';
import { useRoute, useRouter } from 'vue-router';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useToast } from 'primevue/usetoast';

export default {
	setup() {
		const toast = useToast();
		const router = useRouter();
		const route = useRoute();

		const loading = ref(false);
		const pageLoading = ref(true);
		const showUpdate = ref(false);
		const submitted = ref(false);

		const refundRequestList = ref(null);
		const selectedItem = ref(null);
		const filters = ref({});
        const agencies = ref();
        const QueryStartDate = ref();
        const QueryEndDate = ref();
        const QueryAgency = ref();
        const QueryStatus = ref();
		const paginationValue = ref(10);
		const pageValue = ref();

        const statusOptions = ref([
            {value: '0', name: 'Refund pending', key: 'M'},
            {value: '1', name: 'Refund completed', key: 'O'},
            {value: '-1', name: 'Refund declined', key: 'O'},
        ]);

		const state = reactive({
            status: '',
            note: '',
            agency: '',
		});

		const rules = {
            status: { required },
            note: {  },
            agency: {  },
		};

		const valid$ = useVuelidate (rules, state);

		const getActiveQuerys = () => {
            if(route.query.page != null){
                pageValue.value = route.query.page;
            }
            if(route.query.pagination != null){
                paginationValue.value = parseInt(route.query.pagination);
            }
            if(route.query.agencyId != null){
                QueryAgency.value = route.query.agencyId;
            }
            if(route.query.start_date != null){
                QueryStartDate.value = route.query.start_date;
            }
            if(route.query.end_date != null){
                QueryEndDate.value = route.query.end_date;
            }
            if(route.query.status != null){
                QueryStatus.value = route.query.status;
            }
        };
        getActiveQuerys();

		//Refund LIST
		const getRefundRequest = () =>{
			Axios
			.get("api/operator/refund-request/list",{
				params: {
                    page: pageValue.value,
                    pagination: paginationValue.value,
                    agencyId: QueryAgency.value,
                    start_date: QueryStartDate.value,
                    end_date: QueryEndDate.value,
                    status: QueryStatus.value,
                }
			}
			)
			.then((res) =>{
				pageLoading.value = false;
				if (res.data.response == "success") {
					refundRequestList.value = res.data.data;
					refundRequestList.value.from = refundRequestList.value.from-1;
                    console.log(refundRequestList.value);
				}
				else{
					console.log(res.data.msg);
				}
			})
			.catch((err) =>{
				console.log(err);
				pageLoading.value = false;
			})
		};
		getRefundRequest();


		const getQueryAgency = () => {
			Axios
			.get("api/admin/agency/dropdown-list")
			.then((res) => {
				if (res.data.response == "success") {
					agencies.value = res.data.data;
				}
				else{
					console.log(res.data.message);
				}
			})
			.catch((err) => {
				console.log(err);
			})
		};
		getQueryAgency();


		//OTHER FUNCTIONS
		const showToast = (severity, summary, detail) => {
			toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
		};

		const formatDate = (rawDate) => {
            return moment(rawDate).format("Do MMMM YYYY");
        };

		const formatTime = (rawDate) => {
            return moment(rawDate).format("Do MMMM YYYY hh:mm:ss a");
        };


		const hideDialog = () => {
			showUpdate.value = false;
			submitted.value = false;
            selectedItem.value = null;

            state.agency = '';
            state.status = '';
            state.note = '';
		};


		//EDIT OR UPDATE
		const updateRefund = (product) => {
			selectedItem.value = product;
			showUpdate.value = true;

            state.status = selectedItem.value.status;
            state.agency = selectedItem.value.agency;
		};

		const submitUpdate = (isFormValid) => {
			submitted.value = true;
            if (!isFormValid) {
                return;
            }

			const formData1 = new FormData();

            formData1.append("status", state.status);
            formData1.append("note", state.note);

			formData1.append("_method", "PUT");

			Axios
            .post(`api/admin/refund-request/update/${selectedItem.value.id}`, formData1)
            .then( (res) => {
                loading.value = false;
                if(res.data.response=="success") {
                    console.log(res);
                    showToast("success", "Success", res.data.message);
                    selectedItem.value = null;
					showUpdate.value = false;
					getRefundRequest();
					state.agency = '';
					state.status = '';
					state.note = '';
                }
                else{
                    showToast("error", "Error", res.data.message);
                    console.log(res.data.message);
                }
            })
            .catch((err) => {
                showToast("error", "Error", err.message);
                console.log(err);
            });
		};

		//FILTER
		const getFilteredList = () => {
            pageLoading.value = true;

            var queryParams = {};
            if(QueryAgency.value != null){
                queryParams.agencyId = QueryAgency.value;
            }
            if(QueryStatus.value != null){
                queryParams.status = QueryStatus.value;
            }
            if(QueryStartDate.value != null){
                queryParams.start_date = QueryStartDate.value;
            }
            if(QueryEndDate.value != null){
                queryParams.end_date = QueryEndDate.value;
            }

            router.replace({ name: 'refund-request', query: { ...queryParams } });

            getRefundRequest();
        };

        const clearFilters = () => {
            pageLoading.value = true;

            QueryAgency.value = null;
            QueryStartDate.value = null;
            QueryEndDate.value = null;
            QueryStatus.value = null;
            router.replace();

            getRefundRequest();
        };
		
		const onPage = (event) => {
			pageValue.value = event.page + 1;
			paginationValue.value = event.rows;
			pageLoading.value = true;

            var queryParams = {};
            if(pageValue.value != null){
                queryParams.page = pageValue.value;
            }
            if(paginationValue.value != null){
                queryParams.pagination = paginationValue.value;
            }
            router.replace({ name: 'refund-request', query: { ...queryParams } });

            getRefundRequest();
		}

		return{
			pageLoading,
			valid$,
			showUpdate,
			selectedItem,
			filters,
			submitted,
			refundRequestList,
			loading,
			formatDate,
			formatTime,
			updateRefund,
			submitUpdate,
			hideDialog,
            QueryAgency,
            QueryStatus,
            QueryStartDate,
            QueryEndDate,
			paginationValue,
			clearFilters,
			getFilteredList,
			onPage,
            statusOptions,
			agencies,
            state
		}
	},
}
</script>

<style scoped lang="scss">
	@import '../assets/demo/badges.scss';
</style>
